@keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}

.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: #04534a;
    overflow: hidden;
    z-index: -1000;
}
.background li {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 10px;
    height: 10px;
    border: solid 1px #00ae99;
    color: transparent;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 10s ease-in forwards infinite;
}

.background li:nth-child(0) {
    animation-delay: 0s;
    left: 68vw;
    top: 43vh;
}

.background li:nth-child(1) {
    animation-delay: 0s;
    left: 75vw;
    top: 89vh;
}

.background li:nth-child(2) {
    animation-delay: 0s;
    left: 34vw;
    top: 23vh;
    border-color: #00d5bc;
}

.background li:nth-child(3) {
    animation-delay: 0s;
    left: 26vw;
    top: 75vh;
}

.background li:nth-child(4) {
    animation-delay: 0s;
    left: 93vw;
    top: 57vh;
}

.background li:nth-child(5) {
    animation-delay: 0s;
    left: 6vw;
    top: 77vh;
}

.background li:nth-child(6) {
    animation-delay: 0s;
    left: 50vw;
    top: 31vh;
    border-color: #00d5bc;
}

.background li:nth-child(7) {
    animation-delay: 4s;
    left: 36vw;
    top: 12vh;
}

.background li:nth-child(8) {
    animation-delay: 6s;
    left: 54vw;
    top: 95vh;
    border-color: #00d5bc;
}

.background li:nth-child(9) {
    animation-delay: 8s;
    left: 64vw;
    top: 61vh;
    border-color: #00d5bc;
}

.background li:nth-child(10) {
    animation-delay: 10s;
    left: 84vw;
    top: 4vh;
    border-color: #00d5bc;
}

.background li:nth-child(11) {
    animation-delay: 12s;
    left: 0vw;
    top: 40vh;
    border-color: #00d5bc;
}

.background li:nth-child(12) {
    animation-delay: 0s;
    left: 97vw;
    top: 26vh;
    border-color: #00d5bc;
}

.background li:nth-child(13) {
    animation-delay: 0s;
    left: 18vw;
    top: 7vh;
    border-color: #00d5bc;
}

.background li:nth-child(14) {
    animation-delay: 0s;
    left: 46vw;
    top: 50vh;
    border-color: #00d5bc;
}

.background li:nth-child(15) {
    animation-delay: 0s;
    left: 16vw;
    top: 86vh;
    border-color: #00d5bc;
}

.background li:nth-child(16) {
    animation-delay: 0s;
    left: 82vw;
    top: 68vh;
    border-color: #00d5bc;
}
