@import url('https://fonts.googleapis.com/css2?family=League+Spartan&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'League Spartan', sans-serif;
}

html,
body,
#root {
  height: 100%;
}

.bouncingarrow {
  animation: bounce 1s linear infinite
}

@keyframes bounce {
  0% {
      transform: translateY(0);
      animation-timing-function: ease-out;
 }
  50% {
      transform: translateY(-2rem);
      animation-timing-function: ease-in;
 }

  100% {
      transform: translateY(0);
      animation-timing-function: ease-out;
 }
}
